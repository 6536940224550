import * as React from 'react'
import styled, { css } from 'styled-components'
import ProductImage from 'theme/atoms/ProductImage'
import ColorPreview from './ColorPreview'
import Price, * as Prices from 'theme/atoms/Price'
import Link from 'theme/atoms/Link'
import Flags from 'theme/molecules/Flags'
import * as evt from './events'
import * as t from './types'
import theme from 'theme'
import B2cSwitch from 'theme/atoms/B2cSwitch'
import EnergyLabel from 'theme/atoms/EnergyLabel'
import useInView from 'hooks/useInView'
import useTranslations from 'hooks/useTranslations'
import useProductWidgetContext from 'hooks/useProductWidgetContext'
import Rating from 'theme/molecules/Rating'
// wdv 1606
export type Props = {
  product: t.Product
  listname: string
  listPosition: number
  listid: string
  layoutSize?: string
  algoliaQueryID?: string
  loading?: boolean
}

export default function ProductWidget(props: Props) {
  const {
    title,
    brand,
    images,
    containerID,
    prices,
    variantImages,
    attributes,
    productReviews
  } = props.product

  const t = useTranslations<'molecules-ProductWidget'>()
  const { showVariants, onProductWidgetClick, linkTo } =
    useProductWidgetContext()
  const [ref, isInView] = useInView(-200, true)
  const [mainImage, setMainImage] = React.useState('')
  const layoutSize = props.layoutSize ? props.layoutSize : 'normal'

  React.useEffect(() => {
    if (!isInView) return
    evt.scrollIntoView(props.product, props.listname, props.listPosition)
  }, [isInView, props.product, props.listname, props.listPosition])

  return (
    <Wrapper
      ref={ref}
      className="ProductWidget"
      to={linkTo ? linkTo : `/pdp/${containerID}/`}
      onClick={() => {
        evt.widgetClick(
          props.product,
          props.listname,
          props.listid,
          props.listPosition,
          props.algoliaQueryID,
          props.product.dyContext
        )
        if (onProductWidgetClick) {
          onProductWidgetClick(props.product)
        }
      }}
      data-cy-ctx="molecules/ProductWidget"
      $layoutSize={layoutSize}
      $loading={props.loading ?? false}
      title={title}
    >
      <div className="flag-wrapper">
        <Flags product={props.product} type={'widget'} />
      </div>
      <ImgBox className="ImgBox" data-cy-handle="click-out">
        {attributes?.ENERGY_EFFICIENCY_CLASS && (
          <EnergyLabel
            data-cy-state="show-energylabel"
            energyLabelLink={attributes.ENERGY_LABEL?.values[0].document}
            label={attributes.ENERGY_EFFICIENCY_CLASS?.values[0].value}
            size={'small'}
          />
        )}

        <ProductImage
          img={
            mainImage
              ? { url: mainImage, classes: ['ASSET_FS'] }
              : images.imageWeb[0]
          }
          size="s"
          alt={title}
        />
        {/* TODO: Colors inkl. Hover und Klick zu SKU, wenn Daten aus Algolia da */}
        {showVariants && (
          <ColorPreview
            data-cy-state="colorpreview-is-visible"
            variantImages={variantImages}
            onImageHover={setMainImage}
            containerId={containerID}
            onColorClick={(variantImage) =>
              evt.colorClick(
                variantImage,
                props.product,
                props.listname,
                props.listPosition,
                props.product.dyContext
              )
            }
          />
        )}
      </ImgBox>
      <div className="brand">{brand ?? ' '}</div>
      <div className="title">
        <span>{title}</span>
      </div>
      <div className="price">
        {prices.productCheapestPiecePriceNet &&
          prices.productMostexpensivePiecePriceNet &&
          prices.productCheapestPiecePriceNet <
            prices.productMostexpensivePiecePriceNet && (
            <div data-cy-state="has-cheapestprice" className="labels">
              <span
                className={
                  Prices.PriceHasStrikePrice(props.product, 1)
                    ? 'strike-reduced'
                    : ''
                }
              >
                {t('from_price')}{' '}
                <Prices.Cheapest prices={prices} logic="piece" />
              </span>
              {Prices.PriceHasStrikePrice(props.product, 1) && (
                <span className="strike" data-cy-state="has-strikeprice">
                  <Prices.PriceStrike
                    prices={prices}
                    logic="productCheapestPiece"
                  />
                </span>
              )}
            </div>
          )}

        {prices.productCheapestPiecePriceNet &&
          prices.productMostexpensivePiecePriceNet &&
          prices.productCheapestPiecePriceNet >=
            prices.productMostexpensivePiecePriceNet && (
            <div data-cy-state="has-strikeprice" className="labels">
              <span
                className={
                  Prices.PriceHasStrikePrice(props.product, 1)
                    ? 'strike-reduced'
                    : ''
                }
              >
                <Price prices={prices} logic="piece" />
              </span>
              {Prices.PriceHasStrikePrice(props.product, 1) && (
                <span className="strike" data-cy-state="has-strikeprice">
                  <Prices.PriceStrike prices={prices} logic="piece" />
                </span>
              )}
            </div>
          )}

        <div className="details">
          {prices.productCheapestPiecePriceNet &&
          prices.productMostexpensivePiecePriceNet &&
          prices.productCheapestPiecePriceNet <
            prices.productMostexpensivePiecePriceNet &&
          props.product.unit.purchaseUnit > 1 ? (
            <div data-cy-state="has-purchaseUnit-greater-one">
              <span>
                {t('from_price')}{' '}
                <Prices.Cheapest prices={prices} logic="pack" />
                {' / '}
                {prices.productCheapestMinSkuContentCount}{' '}
                {prices.productCheapestMinSkuContentUnit}
              </span>
            </div>
          ) : (
            props.product.unit.purchaseUnit > 1 && (
              <div data-cy-state="has-purchaseUnit-greater-one">
                <span>
                  <Price
                    prices={props.product.prices}
                    logic="pack"
                    scalePrice={props.product.priceRules}
                  />
                  {' / '}
                  {props.product.unit.purchaseUnit}{' '}
                  {props.product.unit.unitName}
                </span>
              </div>
            )
          )}
          {props.product.prices.referencePriceNetString && (
            <span data-cy-state="has-baseprice">
              <B2cSwitch.B2B>
                {props.product.prices.referencePriceNetString}
              </B2cSwitch.B2B>
              <B2cSwitch.B2C>
                {props.product.prices.referencePriceGrossString}
              </B2cSwitch.B2C>
            </span>
          )}
        </div>
      </div>
      {productReviews && (
        <Rating
          data-cy-state="show-rating"
          containerID={containerID}
          ratingAverage={productReviews.productReviewAvg}
          ratingCount={productReviews.productReviewCount}
        />
      )}
    </Wrapper>
  )
}

const ImgBox = styled.div`
  position: relative;

  > img {
    margin: 0 auto;
  }

  > .EnergyLabel {
    position: absolute;
    right: 0.75rem;
    top: 0;
  }

  > .ColorPreview {
    margin-top: ${theme.spacing('xs')};
  }
`

const Wrapper = styled(Link)<{ $layoutSize: string, $loading: boolean }>`
  display: block;
  position: relative;

  body.allowOdo && {
    ${p => p.$loading && css`
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 1);
        z-index: 1;
      }
    `}
  }

  > .flag-wrapper {
    position: relative;
    > .Flags {
      z-index: ${theme.zIndex.theme_molecules_productwidget};
      position: absolute;
      left: 0;
    }
  }
  > .brand {
    ${theme.ty('r-xs', '700')}
    color: ${theme.colors.shade.b4};
    margin-top: ${theme.spacing('xxs')};
    ${(props) =>
      props.$layoutSize === 'normal' &&
      `
        ${theme.ty('r-s', '700')}
        margin-top: ${theme.spacing('xs')};
    `}

    ${(props) =>
      props.$layoutSize === 'small' &&
      `
        ${theme.ty('r-xs', '700')}
      }
    `}
  }

  > .title {
    position: relative;
    height: 1.5rem;
    overflow: hidden;
    margin-bottom: ${theme.spacing('xxs')};
    ${(props) =>
      props.$layoutSize === 'small' &&
      `
           height: 1.125rem; 
      `}
    > span {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      margin-bottom: ${theme.spacing('xxs')};
      max-width: 100%;
      white-space: nowrap;
      ${theme.ty('r-s')};
      ${(props) =>
        props.$layoutSize === 'normal' &&
        `
          @media (min-width: ${theme.ms.MD}px) {
            ${theme.ty('r-base')};
          }      
      `}
    }
  }

  > .price {
    ${theme.ty('r-s', '700')};
    ${(props) =>
      props.$layoutSize === 'normal' &&
      `
        @media (min-width: ${theme.ms.MD}px) {
        ${theme.ty('r-base', 'bold')}
      }    
    `}

    > .labels {
      display: flex;
      align-items: center;
      gap: ${theme.spacing('xxs')};
    }
    .croatiaCurrency {
      width: 68px;
      height: 18px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 18px;
      color: #625d52;
      flex: none;
      order: 0;
      flex-grow: 0;
    }

    & .strike {
      color: ${theme.colors.shade.b3};
      text-decoration: line-through;
      margin-right: ${theme.spacing('xs')};
      ${theme.ty('r-xs')};
      ${(props) =>
        props.$layoutSize === 'normal' &&
        `
        @media (min-width: ${theme.ms.MD}px) {
        ${theme.ty('r-base')};
      }
    `}
    }
    & .strike-reduced {
      color: ${theme.colors.accent.red};
    }
    & .details {
      color: ${theme.colors.shade.b3};

      ${theme.ty('r-xs')};
      ${(props) =>
        props.$layoutSize === 'normal' &&
        `
        @media (min-width: ${theme.ms.MD}px) {
        ${theme.ty('r-s')};
      }
    `}
    }
  }
  > .Rating {
    margin-top: ${theme.spacing('xs')};
    margin-bottom: ${theme.spacing('xxs')};
  }
`
